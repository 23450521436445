import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import StepsComponent from "../components/ProgramAfiliere/StepsComponent"
import "/src/assets/css/ProgramAfiliere/program-afiliere.css"
import InfoBlue from "../components/ProgramAfiliere/InfoBlue"
import HeroSection from "../components/ProgramAfiliere/HeroSection"
import SEO from "../components/App/SEO"
import InfoBlocks from "../components/Index/InfoBlocks"

const Afiliere = () => {
  return (
    <Layout>
      <SEO title="Afiliere Hosting - Sitebunker.ro - Găzduire eficientă și securitate sporită"
          description="Înscrie-te la programul de Afiliere Hosting SiteBunker și bucură-te de comisionul avantajos pentru orice abonament lunar sau anual recurent adus."/>
      <Navbar />
      <HeroSection />
      <InfoBlue />
      <StepsComponent />
      <div className="container">
        <h3 className="text-center">De ce să recomanzi serviciile SiteBunker?</h3>
      </div>
      <InfoBlocks/>
      <Footer />
    </Layout>
  )
}
export default Afiliere
