import React from "react"
import SvgAfiliere from "../../assets/svgComponents/afiliere.svg"
import ProgramAfiliere from "../../assets/images/new-imgs/program-afiliere.png"

const HeroSection = () => {
  return (
    <div className="page-title-area affiliate-program">
      <div className="container">
        <div className="page-title-content text-left">
          {/*<SvgAfiliere className={"svgafiliere"} />*/}
          <img src={ProgramAfiliere} className='svgafiliere' alt="afiliere" loading="lazy"/>
          <div className="row">
            <div className="col-md-7 col-12">
              <h2 className="mb-4 responsive">Program Afiliere SiteBunker</h2>
              <p className="font-weight-normal">Acum este simplu să fii partener SiteBunker. Înscrie-te în programul
                nostru de afiliere hosting și beneficiază de 15% comision la fiecare plată recurentă.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
