import React from "react"

import nrOne from "/src/assets/images/program-afiliere/nr-1.png"
import nrTwo from "/src/assets/images/program-afiliere/nr-2.png"
import nrThree from "/src/assets/images/program-afiliere/nr-3.png"

const StepsComponent = () => {
  return (

    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrOne} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">Înscrie-te</p>
                <span className="step-info">Contactează-ne pentru a obține link-ul de afiliat</span>
              </div>
            </div>
          </div>

          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrTwo} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">Inserează codul HTML</p>
                <span className="step-info">Începe să câștigi bani prin clienții aduși la SiteBunker</span>
              </div>
            </div>
          </div>

          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrThree} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">Alege banner-ul</p>
                <span
                  className="step-info">Obții codul HTML direct din contul de client și selectezi bannerul preferat</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <p className="mb-4"><strong>Ce se întâmplă cu comisionul de afiliat hosting SiteBunker?</strong></p>
          <p className="mb-4">Comisionul câștigat este alocat și vizibil în contul de client creat, pe care îl vei
            putea retrage sub formă de numerar**, dar și achita facturile serviciilor active Sitebunker.ro, precum
            și achiziționa noi servicii de găzduire web Sitebunker.ro. Suma minimă pe care o vei putea folosi sau
            retrage este de 10 euro.</p>
          <p className="mb-0 small">*Sunt excluse comenzile pentru nume de domenii, servere VPS si pachete de gazduire reseller.</p>
          <p className="mb-0 small">**Retragerea sub forma de numerar se poate face doar pe baza unei facturi fiscale
            emise de către tine (este necesar sa deții un SRL sau un PFA).</p>
        </div>
      </div>
    </div>

  )
}

export default StepsComponent
