import React from "react"
import { Link } from "gatsby"


const InfoBlue = () => {
  return (

    <div id="affiliate-info" className="main-blue-bg  text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-12 mx-auto">
            <h3  className=" text-white mb-5 font-weight-normal text-fix-h2">Garantat ai <strong>15% COMISION</strong> pentru fiecare plată recurentă realizată de clienții aduși de tine.
            </h3>
            <h4  className=" text-white mb-5 font-weight-normal text-fix-h4">
              Pentru fiecare client convins de tine să achiziționeze orice pachet de găzduire cu plata lunară sau anuală,
              vei primi automat 15% COMISION din valoarea fiecărei plăți efectuate.

            </h4>
            <h4 className=" text-white mb-5">Aplică ACUM la programul SiteBunker de afiliere hosting.</h4>
            <Link to="/contact/" className="form-btn  d-inline-block mb-5 ">Contactează-ne aici</Link>
            <h4 className=" text-white font-weight-normal">Intră în legătură cu echipa suport SiteBunker și află mai multe detalii.</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBlue
